import { useEffect } from 'react'
import Head from 'next/head'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import TagManager from 'react-gtm-module'
import { wrapper } from '../redux/store'
import { useDispatch } from 'react-redux'
import { getImportantServices } from '../redux/apiSlice'
import { socialLogin } from '../redux/authSlice'
import {
  errorHandling,
  getAuthTokenFromCookie,
  openNotification,
} from '../utils/utils'

import Layout from '../components/layout-components/homeLayout/Layout'
import Welcome from '../components/shared-components/blocks/Welcome'

const HowItWorks = dynamic(
  () => import('../components/shared-components/blocks/HowItWorks'),
  {
    ssr: true,
  }
)

const WhyUs = dynamic(
  () => import('../components/shared-components/blocks/WhyUs'),
  {
    ssr: true,
  }
)

const ImportantServices = dynamic(() =>
  import('../components/shared-components/blocks/ImportantServices')
)

const CallCenter = dynamic(() =>
  import('../components/shared-components/blocks/CallCenter')
)

const Reviews = dynamic(() =>
  import('../components/shared-components/blocks/Reviews')
)

const Home = ({ services }) => {
  const dispatch = useDispatch()
  const { query, push } = useRouter()

  // SOCIAL LOGIN
  useEffect(() => {
    if (query.code) {
      dispatch(socialLogin(query.code))
        .unwrap()
        .then(() => {
          if (localStorage.getItem('SMARTCAF_conversation_token')) {
            push('/dashboard/tickets')
          } else if (localStorage.getItem('redirection_link')) {
            router.push(localStorage.getItem('redirection_link'))
            localStorage.removeItem('redirection_link')
          } else if (localStorage.getItem('SMARTCAF_SERVICE_ID')) {
            push(
              `/dashboard/services/${localStorage.getItem(
                'SMARTCAF_SERVICE_ID'
              )}/request`
            )
            localStorage.removeItem('SMARTCAF_SERVICE_ID')
          } else {
            push('/dashboard/services')
          }

          if (query.operation === 'register') {
            const tagManagerArgs = {
              dataLayer: {
                event: 'register',
                eventCategory: 'Registration',
                eventAction: null,
                eventLabel: 'user',
              },
              dataLayerName: 'PageDataLayer',
            }
            TagManager.dataLayer(tagManagerArgs)
          } else {
            const tagManagerArgs = {
              dataLayer: {
                event: 'login',
                eventCategory: 'Login',
                eventAction: 'user',
              },
              dataLayerName: 'PageDataLayer',
            }
            TagManager.dataLayer(tagManagerArgs)
          }
        })
        .catch((error) => openNotification('error', errorHandling(error)))
    }
  }, [dispatch, push, query.code, query.operation])

  return (
    <div>
      <Head>
        <title>Smartcaf</title>
        <link rel='icon' href='/favicon.ico' />
        <meta
          name='description'
          content='Evita le code, siamo il primo CAF in Italia completamente online!'
        />
      </Head>

      <Welcome />
      <HowItWorks />
      <WhyUs />
      <ImportantServices data={services} />
      <CallCenter />
      <Reviews />
    </div>
  )
}

Home.Layout = Layout
export default Home

export const getServerSideProps = wrapper.getServerSideProps(
  (store) => async (ctx) => {
    const { req, locale } = ctx

    const token = req.headers.cookie
      ? getAuthTokenFromCookie(req.headers.cookie)
      : null

    const { data: servicesData } = await store.dispatch(
      getImportantServices.initiate({ locale, token })
    )

    return {
      props: {
        ...(await serverSideTranslations(locale, [
          'Home',
          'Services',
          'HomeLayout',
        ])),
        services: servicesData,
      },
    }
  }
)
