import Image from 'next/image'

const ResponsiveImage = ({ src, alt, width, height, blur, sizes }) => {
  return (
    <div className='relative !w-full'>
      <Image
        src={src}
        alt={alt}
        width={width}
        height={height}
        priority={true}
        placeholder={blur ? 'blur' : 'empty'}
        sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
        layout='responsive'
      />
    </div>
  )
}

export default ResponsiveImage