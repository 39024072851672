import Script from 'next/script'
import { useEffect, useRef } from 'react'

const TrustBox = ({ theme = 'light', templateId, height }) => {
  const ref = useRef(null)

  useEffect(() => {
    const tryInitTrustpilotWidget = () => {
      if (window.Trustpilot && ref.current) {
        window.Trustpilot.loadFromElement(ref.current, true)
      } else {
        setTimeout(tryInitTrustpilotWidget, 500)
      }
    }

    tryInitTrustpilotWidget()
  }, [templateId, height, theme])

  return (
    <>
      <Script
        src='//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
        strategy='lazyOnload'
      />
      <div
        ref={ref}
        className='trustpilot-widget'
        data-locale='it-IT'
        data-template-id={templateId}
        data-businessunit-id='611bb39c0f8bab001f63afb9'
        data-style-height={height}
        data-style-width='100%'
        data-theme={theme}
        data-stars='4,5'
      >
        <a
          href='https://it.trustpilot.com/review/smartcaf.it'
          target='_blank'
          rel='noreferrer'
        ></a>
      </div>
    </>
  )
}

export default TrustBox
