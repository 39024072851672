import { useState } from 'react'
import { useRouter } from 'next/router'
import { Row, Col, Button } from 'antd'
import { useTranslation } from 'next-i18next'
import { useSelector } from 'react-redux'
import { webAssets } from '../../../utils/webAssets'

import ResponsiveImage from '../ResponsiveImage'
import TrustBox from '../Trustpilot'
import Register from '../modals/auth/Register'

const Welcome = () => {
  const { t } = useTranslation('Home')
  const router = useRouter()

  const { isAuth } = useSelector((state) => state.auth)
  const { navbarHeight } = useSelector((state) => state.theme)

  const [registerOpen, setRegisterOpen] = useState(false)

  return (
    <>
      <div
        className='hero-section py-20 sm:py-40 md:py-60 xxl:py-60 flex items-center'
        style={{ height: `calc(100vh - ${navbarHeight}px)` }}
      >
        <div className='container mx-auto'>
          <Row justify='center' align='middle' gutter={[55, 16]}>
            <Col
              xs={{ order: 2, span: 24 }}
              md={{ order: 1, span: 12 }}
              xxl={{ order: 2, span: 11 }}
            >
              <div>
                <h4 className='text-base text-green font-semibold uppercase mb-16'>
                  {t('welcome.welcome')}
                </h4>
                <h1 className='text-[2rem] lg:text-[2.5rem] leading-[3rem] lg:leading-[3.5rem] font-medium mb-16'>
                  {t('welcome.paragraph')}
                  <span className='font-bold text-green'>
                    {' '}
                    {t('welcome.paragraphBold')}
                  </span>
                </h1>
                <h2 className='text-xl text-[#6D6D6D] font-normal leading-9 mb-50'>
                  {t('welcome.hint')}
                </h2>

                <div className='flex items-center'>
                  <Button
                    className='btn-green text-lg font-bold rounded-lg tracking-wider h-auto py-20 px-40'
                    onClick={() => {
                      if (isAuth) {
                        router.push('/services')
                      } else {
                        setRegisterOpen(true)
                      }
                    }}
                  >
                    {isAuth ? t('Inizia qui') : t('Registrati ora')}
                  </Button>
                  <div className='trust-pilot mx-24'>
                    <TrustBox
                      theme='light'
                      templateId='53aa8807dec7e10d38f59f32'
                      height='150px'
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col
              xs={{ order: 1, span: 18 }}
              sm={{ order: 1, span: 24 }}
              md={{ order: 2, span: 12 }}
              xxl={{ order: 2, span: 13 }}
            >
              <ResponsiveImage
                alt='Benvenuto in SmartCaf'
                src={webAssets.heroImg}
                width={493}
                height={424}
                blur={true}
              />
            </Col>
          </Row>
        </div>
      </div>

      <Register open={registerOpen} setOpen={setRegisterOpen} />
    </>
  )
}

export default Welcome
